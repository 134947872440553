@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap');

.skillsColumn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 22%;
    height: auto;
    margin: 1rem 1rem 1rem 1rem;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
    border: 2px solid black;
}

.columnType-title {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    margin: 1rem 1rem 1rem 1rem;
    font-size: 1.10vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.skill-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: .5rem .5rem .5rem .5rem;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem .5rem .5rem .5rem;
}

.icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 3px 3px black;
} 