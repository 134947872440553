@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap);
/* * {
  border: 2px solid black;
} */

body {
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url("https://res.cloudinary.com/gregdusek/image/upload/v1627497221/Dev%20Site%20Images/wallhaven-nkpopd_wdt1sk.jpg"); 
}

.App {
  display: flex;
  flex-direction: column;
  font-family: 'Inconsolata', monospace;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: white;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.page-title {
  font-family: 'Inconsolata', monospace;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  margin: 1rem 1rem 1rem 1rem;
  font-size: 2.5vw;
  font-weight: 500;
  text-shadow: 3px 3px black;
}

.aboutColumn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
}

.about-img {
    display: flex;
    width: 30%;
    height: auto;
    object-fit: cover;
    margin: .5rem .5rem .5rem .5rem;
    box-shadow: 3px 3px black;
}

.column-paragraph {
    display: flex;
    flex-direction: center;
    justify-content: left;
    line-height: 1.00vw;
    width: 95%;
    height: auto;
    margin: 1rem auto;
    font-size: 1vw;
}

.contactColumn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80vw;
    height: auto;
    margin: 5rem auto;
}

.contact{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.contact-title {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    margin: 1rem 1rem 1rem 1rem;;
    font-size: 1.5vw;
    font-weight: 700;
    text-shadow: 3px 3px black;
    text-decoration: none;
    -webkit-text-decoration-color: none;
            text-decoration-color: none;
    color: white;
}

.contact-paragraph {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    height: auto;
    line-height: 1.05vw;
    font-size: 1.5vw;
    font-weight: 700;
    margin: 1rem 1rem 1rem 1rem;;
    text-shadow: 2px 2px black;
    text-decoration: none;
    -webkit-text-decoration-color: none;
            text-decoration-color: none;
    color: white;
}

.linked-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 1rem auto;
    text-shadow: 2px 2px black;
    text-decoration: none;
    -webkit-text-decoration-color: none;
            text-decoration-color: none;
    color: white;
}

.contact-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: 1rem 1rem 1rem 1rem;
}

.linked-title {
    display: flex;
    flex-direction: center;
    width: auto;
    justify-content: center;
    font-size: 1.5vw;
    font-weight: 700;
    margin: 1rem 1rem 1rem 1rem;
    text-decoration: none;
    -webkit-text-decoration-color: none;
            text-decoration-color: none;
    color: white;
}
.training-row {
    margin: .5rem .5rem .5rem .5rem;
}

.row-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    height: auto;
    margin: 1rem auto;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
}

.training-title {
    display: flex;
    justify-content: left;
    width: auto;
    margin: .5rem .5rem .5rem .5rem;
    font-size: 1.05vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.training-paragraph {
    display: flex;
    justify-content: left;
    width: auto;
    line-height: 1.05vw;
    font-size: .90vw;
    font-weight: 400;
    margin: .5rem .5rem .5rem .5rem;
}

.background-title {
    display: flex;
    justify-content: left;
    width: auto;
    margin: .5rem .5rem .5rem .5rem;
    font-size: 1.05vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.background-row {
    margin: .5rem .5rem .5rem .5rem;
}

.background-paragraph {
    display: flex;
    justify-content: left;
    width: auto;
    line-height: 1.05vw;
    font-size: .90vw;
    font-weight: 400;
    margin: .5rem .5rem .5rem .5rem;
}

.column-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: .5rem .5rem .5rem .5rem;
}

.column-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.git-icon {
    height: 11vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    background-color: white;
    box-shadow: 3px 3px black;
}

.column-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.resume-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    background-color: white;
    box-shadow: 3px 3px black;
}

.column-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32%;
    margin: .5rem .5rem .5rem .5rem;
}

.linked-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    box-shadow: 3px 3px black;
}

.column-title {
    display: flex;
    width: 60%;
    justify-content: center;
    font-size: 1vw;
    font-weight: 700;
    margin: .5rem .5rem .5rem .5rem;
    text-shadow: 2px 2px black;
}
.page-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.name{
    display: flex;
    justify-content: left;
    width: auto;
    height: auto;
    font-size: 2.5vw;
    font-weight: 700;
    margin: 1rem 1rem 1rem 1rem;
    padding: .5rem .5rem;
    text-shadow: 3px 3px black;
}

.typed {
    display: flex;
    flex-direction: left;
    justify-content: left;
    align-items: center;
    width: auto;
    height: auto;
    margin: 10rem 1rem 1rem 1rem;
    text-shadow: 3px 3px black;
}

.typed h1 {
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1rem 1rem 1rem 1rem;
}

.home-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 1rem 1rem 1rem 1rem;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
}

.home-intro h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 1rem 1rem 1rem 1rem;
}
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    align-content: right;
    width: 95vw;
    height: auto;
    margin: 0 .50vw .50vw .50vw;
}

.nav-link {
    display: flex;
    /* justify-content: center; */
    font-size: 1vw;
    font-weight: 900px;
    width: auto;
    height: auto;
    margin: .10vw .50vw .10vw .10vw;
    padding: .10vw .10vw .10vw .10vw;
    text-decoration: none;
    -webkit-text-decoration-color: none;
            text-decoration-color: none;
    color: white;
    text-shadow: 2px 2px black;
}
.portfolio-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 95%;
    height: auto;
    margin: 1rem auto;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 18%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
    border-radius: 10px;
    box-shadow: 10px 5px 10px 5px rgba(0, 0, 0, 0.6);
    background-color: rgba(218, 102, 0, 0.6);
}
.card-top {
    display: flex;
    object-fit: cover;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.card-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: .5rem .5rem .5rem .5rem;
}

.card-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1vw;
    font-weight: 600;
    text-shadow: 2px 2px black;
}

.card-description {
    display: flex;
    justify-content: left;
    line-height: 1vw;
    font-size: .75vw;
}

.lang-used {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.lang-icon-container {
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    justify-content: space-evenly;
}

.lang-icon {
    height: 2vw;
    width: 2vw;
    border-radius: .5rem;
    background-color: #f0f0f0;
} 

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
    height: auto;
    margin: auto; 
}

.deployed-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: auto;
    line-height: 1vw;
    font-size: .75vw;
    color: #fff;
    text-shadow: 2px 2px black;    
}

.skillsColumn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 22%;
    height: auto;
    margin: 1rem 1rem 1rem 1rem;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
    border: 2px solid black;
}

.columnType-title {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    margin: 1rem 1rem 1rem 1rem;
    font-size: 1.10vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.skill-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: .5rem .5rem .5rem .5rem;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem .5rem .5rem .5rem;
}

.icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 3px 3px black;
} 
