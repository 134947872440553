@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap');

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    align-content: right;
    width: 95vw;
    height: auto;
    margin: 0 .50vw .50vw .50vw;
}

.nav-link {
    display: flex;
    /* justify-content: center; */
    font-size: 1vw;
    font-weight: 900px;
    width: auto;
    height: auto;
    margin: .10vw .50vw .10vw .10vw;
    padding: .10vw .10vw .10vw .10vw;
    text-decoration: none;
    text-decoration-color: none;
    color: white;
    text-shadow: 2px 2px black;
}