.contactColumn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80vw;
    height: auto;
    margin: 5rem auto;
}

.contact{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.contact-title {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    margin: 1rem 1rem 1rem 1rem;;
    font-size: 1.5vw;
    font-weight: 700;
    text-shadow: 3px 3px black;
    text-decoration: none;
    text-decoration-color: none;
    color: white;
}

.contact-paragraph {
    display: flex;
    flex-direction: center;
    justify-content: center;
    width: auto;
    height: auto;
    line-height: 1.05vw;
    font-size: 1.5vw;
    font-weight: 700;
    margin: 1rem 1rem 1rem 1rem;;
    text-shadow: 2px 2px black;
    text-decoration: none;
    text-decoration-color: none;
    color: white;
}

.linked-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 1rem auto;
    text-shadow: 2px 2px black;
    text-decoration: none;
    text-decoration-color: none;
    color: white;
}

.contact-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: 1rem 1rem 1rem 1rem;
}

.linked-title {
    display: flex;
    flex-direction: center;
    width: auto;
    justify-content: center;
    font-size: 1.5vw;
    font-weight: 700;
    margin: 1rem 1rem 1rem 1rem;
    text-decoration: none;
    text-decoration-color: none;
    color: white;
}