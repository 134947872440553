@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap');

.training-row {
    margin: .5rem .5rem .5rem .5rem;
}

.row-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    height: auto;
    margin: 1rem auto;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
}

.training-title {
    display: flex;
    justify-content: left;
    width: auto;
    margin: .5rem .5rem .5rem .5rem;
    font-size: 1.05vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.training-paragraph {
    display: flex;
    justify-content: left;
    width: auto;
    line-height: 1.05vw;
    font-size: .90vw;
    font-weight: 400;
    margin: .5rem .5rem .5rem .5rem;
}

.background-title {
    display: flex;
    justify-content: left;
    width: auto;
    margin: .5rem .5rem .5rem .5rem;
    font-size: 1.05vw;
    font-weight: 700;
    text-shadow: 2px 2px black;
}

.background-row {
    margin: .5rem .5rem .5rem .5rem;
}

.background-paragraph {
    display: flex;
    justify-content: left;
    width: auto;
    line-height: 1.05vw;
    font-size: .90vw;
    font-weight: 400;
    margin: .5rem .5rem .5rem .5rem;
}

.column-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: .5rem .5rem .5rem .5rem;
}

.column-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.git-icon {
    height: 11vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    background-color: white;
    box-shadow: 3px 3px black;
}

.column-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.resume-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    background-color: white;
    box-shadow: 3px 3px black;
}

.column-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32%;
    margin: .5rem .5rem .5rem .5rem;
}

.linked-icon {
    height: 10vh;
    width: 10vh;
    border-radius: .5rem;
    margin: .5rem .5rem .5rem .5rem;
    box-shadow: 3px 3px black;
}

.column-title {
    display: flex;
    width: 60%;
    justify-content: center;
    font-size: 1vw;
    font-weight: 700;
    margin: .5rem .5rem .5rem .5rem;
    text-shadow: 2px 2px black;
}