@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap');

.portfolio-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 95%;
    height: auto;
    margin: 1rem auto;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 18%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
    border-radius: 10px;
    box-shadow: 10px 5px 10px 5px rgba(0, 0, 0, 0.6);
    background-color: rgba(218, 102, 0, 0.6);
}
.card-top {
    display: flex;
    object-fit: cover;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
}

.card-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: .5rem .5rem .5rem .5rem;
}

.card-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1vw;
    font-weight: 600;
    text-shadow: 2px 2px black;
}

.card-description {
    display: flex;
    justify-content: left;
    line-height: 1vw;
    font-size: .75vw;
}

.lang-used {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.lang-icon-container {
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    justify-content: space-evenly;
}

.lang-icon {
    height: 2vw;
    width: 2vw;
    border-radius: .5rem;
    background-color: #f0f0f0;
} 

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
    height: auto;
    margin: auto; 
}

.deployed-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: auto;
    line-height: 1vw;
    font-size: .75vw;
    color: #fff;
    text-shadow: 2px 2px black;    
}
