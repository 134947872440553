@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;900&display=swap');

.aboutColumn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    height: auto;
    margin: 1rem auto;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%;
    height: auto;
    margin: .5rem .5rem .5rem .5rem;
    background-color: rgba(218, 102, 0, 0.6);
    box-shadow: 3px 3px black;
}

.about-img {
    display: flex;
    width: 30%;
    height: auto;
    object-fit: cover;
    margin: .5rem .5rem .5rem .5rem;
    box-shadow: 3px 3px black;
}

.column-paragraph {
    display: flex;
    flex-direction: center;
    justify-content: left;
    line-height: 1.00vw;
    width: 95%;
    height: auto;
    margin: 1rem auto;
    font-size: 1vw;
}
